import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    plasiyerCustomer: null,
    secondState: true,
    secondMsg: '',
  },
  mutations: {
    PLASIYER_CUSTOMER(state, value) {
      state.plasiyerCustomer = value
    },
    SECOND_STATE(state, value) {
      state.secondState = value
    },
    SECOND_MSG(state, value) {
      state.secondMsg = value
    },
  },
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
  },
})
