import '@/@fake-db/db'

// import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import { createPinia, PiniaVuePlugin } from 'pinia'
import {
  extend, localize, ValidationObserver, ValidationProvider
} from 'vee-validate'
import tr from 'vee-validate/dist/locale/tr.json'
import * as rules from 'vee-validate/dist/rules'
import Vue from 'vue'
import VueCurrencyFilter from 'vue-currency-filter'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.config.productionTip = false
localize('tr', tr)
Vue.use(VueCurrencyFilter, {
  symbol: 'TL',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'back',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
})
Vue.use(PiniaVuePlugin)

// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
const pinia = createPinia()

new Vue({
  pinia,
  router,
  store,

  // i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
