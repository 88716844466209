<template>
  <v-sheet :class="!styleTheme ? 'ma-3' : null" :color="styleTheme ? 'grey' : 'transparent'">
    <v-autocomplete
      v-model="customer"
      label="Müşteri"
      placeholder="Müşteri Seçin"
      persistent-placeholder
      :items="customerItems"
      :loading="customerLoading"
      :filled="styleTheme"
      :background-color="styleTheme ? 'grey' : 'transparent'"
      color="black"
      hide-details
      :rounded="styleTheme"
      item-text="company"
      item-value="customer_id"
      @change="customerChange"
    >
      <template v-if="!$vuetify.breakpoint.mdAndDown" v-slot:prepend>
        <v-icon>{{ icons.mdiDomain }}</v-icon>
      </template>
      <template v-slot:item="{ item }" class="ahmet">
        <v-list-item-content>
          <v-list-item-title class="company-name">
            {{ item.company }}
          </v-list-item-title>
          <v-list-item-subtitle class="company-subtitle">
            {{ item.customer_id }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="company-subtitle">
            {{ item.office_desc || 'Ofis Bilgisi Yok' }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <!--    <v-list-item-action>
          <v-chip color="chip" small label>
            {{ item.limit || 0 | currency }}
          </v-chip>
        </v-list-item-action>-->
      </template>
    </v-autocomplete>
  </v-sheet>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import { mdiDomain } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import Vue from 'vue'

export default {
  props: {
    styleTheme: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const customer = ref(null)
    const customerItems = ref([])
    const customerLoading = ref(false)
    onMounted(() => {
      customerLoading.value = true

      const params = new URLSearchParams()
      params.append('method', 'getCustomersList')
      axios
        .post('', params)
        .then(response => {
          customerItems.value = response.data.detail
          customer.value = store.state.plasiyerCustomer
        })
        .catch(e => {
          // loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
        .finally(() => {
          customerLoading.value = false
        })
    })

    const customerChange = () => {
      const params = new URLSearchParams()
      params.append('method', 'changeActiveCustomer')
      params.append('customer_id', customer.value)
      axios
        .post('', params)
        .then(response => {
          if (response.error === 1) {
            store.commit('SECOND_STATE', false)
            Vue.swal({
              title: 'Uyarı',
              text: response.msg,
              width: '350px',
              icon: 'warning',
              background: '#FFB400',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
          } else {
            // store.commit('SECOND_STATE', false)
            store.commit('PLASIYER_CUSTOMER', customer.value)
            const { accessToken } = response.data
            localStorage.setItem('accessToken', accessToken)
          }
        })
        .finally(() => {
          customerLoading.value = false
        })
    }

    return {
      customerItems,
      customerLoading,
      customer,
      customerChange,
      icons: { mdiDomain },
    }
  },
}
</script>
<style lang="scss">
.v-menu__content:not(.list-style) .v-list-item {
  height: auto !important;
}

.company-name {
  font-size: 1rem; /* Adjust as needed for larger screens */
  font-weight: bold;
  color: black; /* Ensure text color remains consistent */
}

.company-subtitle {
  font-size: 0.8rem; /* Adjust as needed for larger screens */
  color: black; /* Ensure text color remains consistent */
}

/* Font size for mobile screens */
@media (max-width: 600px) {
  .company-name {
    font-size: 0.6rem; /* Adjust the size as needed for mobile screens */
  }
  .company-subtitle {
    font-size: 0.65rem; /* Adjust as needed for larger screens */
  }
}
</style>
