import { mdiCircle, mdiHomeOutline, mdiSunglasses, mdiTableLargePlus, mdiTrayFull } from '@mdi/js'

export default [
  {
    title: 'Gösterge Paneli',
    icon: mdiHomeOutline,
    to: 'dashboard',
  },
  {
    subheader: 'Sipariş',
  },
  {
    title: 'Sipariş Oluşturma',
    icon: mdiTableLargePlus,
    to: 'order-create',
  },
  {
    title: 'Stok Siparişler',
    icon: mdiSunglasses,
    to: 'order-stok',
  },
  {
    title: 'Lens Siparişler',
    icon: mdiCircle,
    to: 'order-lens',
  },

  {
    subheader: 'Rapor',
  },
  {
    title: 'Ekstre',
    icon: mdiTrayFull,
    to: 'extarct-list',
  },
]
